import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import ChatWindow from '../components/chat/ChatWindow';
import './ChatPage.css';

const ChatPage = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  if (!currentUser) {
    return null; // or a loading spinner
  }

  return (
    <Container className="chat-page-container">
      <Box className="header">
        <Typography variant="h4">Welcome to CoachME Chat</Typography>
        <Typography variant="subtitle1">Logged in as: {currentUser.email}</Typography>
      </Box>
      <Box className="chat-window-container"> {/* Add this Box */}
        <ChatWindow />
      </Box>
    </Container>
  );
};

export default ChatPage;
